<template>
  <div class="suggestList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>投诉建议列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>建议类型:</span>
      <el-select
        @clear="onClear(1)"
        v-model="list.opinionType"
        clearable
        placeholder="请选择建议类型"
      >
        <el-option
          v-for="item in typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>是否处理:</span>
      <el-select
        clearable
        @clear="onClear(2)"
        v-model="list.isDispose"
        placeholder="请选择处理结果"
      >
        <el-option
          v-for="item in DisposeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>手机号码:</span>
      <el-input
        v-model="list.opinionPhone"
        placeholder="请输入手机号码"
      ></el-input>
      <el-button type="primary" @click="getOptionList()">查找</el-button>
    </div>
    <el-table border :data="tableDate" style="width: 100%">
      <el-table-column prop="opinionId" label="反馈主键" width="width">
      </el-table-column>
      <el-table-column prop="opinionCont" label="反馈内容" width="width">
      </el-table-column>
      <el-table-column prop="opinionType" label="反馈类型" width="width">
      </el-table-column>
      <el-table-column prop="opinionPhone" label="电话号码" width="width">
      </el-table-column>
      <el-table-column prop="opinionTime" label="反馈时间" width="width">
      </el-table-column>
      <el-table-column prop="opinionDisposeCont" label="处理备注" width="width">
      </el-table-column>
      <el-table-column
        prop="opinionIsDisposeStr"
        label="是否处理"
        width="width"
      >
      </el-table-column>
      <el-table-column prop="opinionUserName" label="用户名" width="width">
      </el-table-column>
      <el-table-column prop="opinionIsDispose" label="是否处理" width="width">
      </el-table-column>
      <el-table-column prop="" label="操作" width="width">
        <template slot-scope="{ row }">
          <el-button type="primary" size="mini" @click="onDel(row)"
            >删除</el-button
          >
          <el-button type="primary" size="mini" @click="onupdate(row)"
            >处理</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      @close="onColse"
      title="处理反馈内容"
      :visible.sync="dialogVisible"
      width="width"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="	处理内容">
          <el-input
            type="textarea"
            rows="6"
            v-model="uptateList.disposeCont"
            placeholder="请输入处理内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { selectOpinion, opinionUpdate, opinionDel } from "../../api/vip";
export default {
  name: "suggestList",
  data() {
    return {
      dialogVisible: false,
      typeOptions: [
        { value: 1, label: "产品建议" },
        { value: 2, label: "服务需求" },
        { value: 3, label: "程序错误" },
      ],
      DisposeOptions: [
        { value: 0, label: "已处理" },
        { value: 1, label: "未处理" },
      ],
      list: {
        opinionPhone: "",
        opinionType: null,
        isDispose: null,
        currentPage: 1,
        pageSize: 5,
      },
      tableDate: [],
      pagination: {},
      uptateList: {
        opinionId: null,
        disposeCont: "",
      },
    };
  },
  created() {
    this.getOptionList();
  },
  methods: {
    onClear(type) {
      if (type == 1) {
        this.list.opinionType = null;
      } else if (type == 2) {
        this.list.isDispose = null;
      }
    },
    onColse() {
      this.uptateList.opinionId = null;
      this.uptateList.disposeCont = "";
    },
    async onUpdateOk() {
      if (this.uptateList.disposeCont == "") {
        return this.$message.error("请填写处理内容");
      }
      const { data } = await opinionUpdate(this.uptateList);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
        this.getOptionList();
        this.dialogVisible = false;
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
        this.dialogVisible = false;
      }
    },
    onupdate(row) {
      this.uptateList.opinionId = row.opinionId;
      this.dialogVisible = true;
    },
    onDel(row) {
      this.$confirm("确认删除这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await opinionDel({
            opinionId: row.opinionId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            tis.getOptionList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async getOptionList() {
      const { data } = await selectOpinion(this.list);
      if (data.code == 0) {
        this.tableDate = data.list;
        this.pagination = data.pagination;
      } else {
        this.$message.error(data.msg);
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getOptionList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getOptionList();
    },
  },
};
</script>
<style lang="less" scoped>
.suggestList {
  .search {
    margin-top: 50px;
    span {
      margin: 0 10px 0 40px;
    }
    .el-input {
      width: 200px;
    }
    .el-button {
      margin: 0 20px;
    }
    .el-select {
      width: 150px;
    }
  }
  .el-table {
    margin-top: 50px;
    .el-table__body-wrapper {
      .tbody {
        .el-button {
        }
      }
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>